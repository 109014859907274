import "flatpickr/dist/themes/light.css";
import { useContext, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import FileUpload from "src/components/embedTypes/forms/FileUpload";
import Button from "src/components/form/Button";
import Checkbox from "src/components/form/Checkbox";
import Input from "src/components/form/Input";
import RadioGroup from "src/components/form/RadioGroup";
import SelectMenu from "src/components/form/SelectMenu";
import TextArea from "src/components/form/TextArea";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { objectToFormData } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import useIframeResize from "src/hooks/useIframeResize";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";
import FormChoiceGrid from "../forms/FormChoiceGrid";
import QuillEditor from "src/components/form/QuillEditor";
import SignatureArea from "src/components/form/SignatureArea";

export default function FormEmbed({ content }) {
  const [sectionIndex, setSectionIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [answers, setAnswers] = useState([]);
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const { maxWidth } = useIframeResize({ workspaceDetails, site });
  const { workspace_id } = useParams();
  const { group, page } = useUrlQueryParams();

  const onHandleChange = (content_id, key, value, question_type, option_id) => {
    setAnswers((prevData) => [
      ...prevData?.map((item) => {
        if (item?.content_id?.toString() === content_id?.toString()) {
          let newValue = value;
          // For checkbox
          if (question_type === "checkbox" && key === "value") {
            if (value === true) {
              newValue = [...(item?.value || []), option_id];
            } else {
              newValue = [...(item?.value || [])?.filter((val) => val !== option_id)];
            }
          }
          if (question_type === "file") {
            newValue = [...(item?.value || []), ...(value || [])];
          }
          return {
            ...item,
            [key]: newValue,
          };
        } else {
          return item;
        }
      }),
    ]);
  };

  const onHandleSetAnswer = () => {
    let ansArr = [];
    if (content?.form?.sections && content?.form?.sections?.length > 0) {
      for (const section of content.form.sections) {
        if (section?.contents && section?.contents?.length > 0) {
          for (const content of section.contents) {
            if (content?.type === "question") {
              ansArr.push({
                content_id: content?._id,
                value: ["checkbox", "checkbox_grid", "radio_grid", "file"]?.includes(content?.question_type)
                  ? ["checkbox_grid", "radio_grid"]?.includes(content?.question_type)
                    ? content?.rows?.map((row) => ({ row_id: row?._id, option_id: content?.question_type === "radio_grid" ? "" : [] }))
                    : []
                  : "",
                other_value: "",
              });
            }
          }
        }
      }
      setAnswers(ansArr);
    }
  };

  const onHandleSubmit = async () => {
    try {
      setDisabled(true);
      const payload = { answers, section_id: content?.form?.sections[sectionIndex]?._id };
      const updatedPayload = objectToFormData(payload);
      const { data } = await apiRequest("POST", `/portal/workspaces/:workspace_id/forms/:form_id`, { params: { workspace_id, form_id: content?.form?._id }, queries: { group, page }, body: updatedPayload }, { headers: { "Content-Type": "multipart/form-data" } });
      if (content?.form?.sections?.length === sectionIndex + 1) {
        toast.success(data.message);
        setSectionIndex(0);
        onHandleSetAnswer();
      } else {
        setSectionIndex((prevData) => prevData + 1);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    onHandleSetAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.form?.sections]);

  return (
    <div
      className={classNames(
        "relative h-full w-full",
        menuType === "horizontal" || menuType === "vertical" ? "" : "", // for horizontal h-screen minus the top menu bar (x2)
      )}>
      <div
        className="mx-auto h-full w-full overflow-auto bg-gray-100"
        style={{ maxWidth }}>
        <div className="mx-auto w-full max-w-[1200px]">
          {content?.form?.sections &&
            content?.form?.sections?.length > 0 &&
            content?.form?.sections?.map(
              (section, index) =>
                sectionIndex === index && (
                  <div
                    className="w-full gap-4 p-6"
                    key={section?._id}>
                    <div className="mx-auto flex-shrink  flex-grow">
                      <div className="space-y-6">
                        {section?.contents &&
                          section?.contents?.length > 0 &&
                          section?.contents?.map((content) => (
                            <div key={content?._id}>
                              {content?.type === "title" && (
                                <div className="relative space-y-2 rounded-md bg-white px-6 py-6 shadow">
                                  <div className="text-2xl font-bold text-gray-600">{content?.name}</div>
                                  <div className="font-medium text-gray-500">{content?.description}</div>
                                </div>
                              )}
                              {content?.type === "question" && (
                                <div className="mb-4 grid gap-y-4 rounded-md bg-white px-6 py-6 shadow">
                                  <div className="w-full">
                                    {content?.question_type === "input" && (
                                      <Input
                                        inline={true}
                                        error={content?.validation?.required}
                                        label={content?.name}
                                        inputValue={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        onChange={(e) => onHandleChange(content?._id, "value", e.target.value, content?.question_type)}
                                      />
                                    )}
                                    {content?.question_type === "textarea" && (
                                      <TextArea
                                        inline={true}
                                        error={content?.validation?.required}
                                        label={content?.name}
                                        value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        onChange={(e) => onHandleChange(content?._id, "value", e.target.value, content?.question_type)}
                                      />
                                    )}
                                    {content?.question_type === "rich_text" && (
                                      <QuillEditor
                                        inline={true}
                                        error={content?.validation?.required}
                                        label={content?.name}
                                        value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        onChange={(e) => onHandleChange(content?._id, "value", e, content?.question_type)}
                                      />
                                    )}
                                    {content?.question_type === "signature" && (
                                      <SignatureArea
                                        inline={true}
                                        error={content?.validation?.required}
                                        label={content?.name}
                                        value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        onChange={(e) => onHandleChange(content?._id, "value", e, content?.question_type)}
                                      />
                                    )}
                                    {content?.question_type === "radio" && (
                                      <div className="space-y-4">
                                        <RadioGroup
                                          required={content?.validation?.required}
                                          name={`option-${content?._id}`}
                                          labelClass={"!font-semibold"}
                                          subtitle={""}
                                          title={content?.name}
                                          defaultChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                          options={content?.options?.map((option) => ({ id: option?._id, title: !option?.other ? option?.name : "Other" }))}
                                          onChange={(id) => onHandleChange(content?._id, "value", id, content?.question_type)}
                                        />
                                        {answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value === content?.options?.find((option) => option?.other)?._id && (
                                          <Input
                                            inline={true}
                                            error={content?.validation?.required}
                                            label={"Specify other"}
                                            inputValue={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.other_value}
                                            onChange={(e) => onHandleChange(content?._id, "other_value", e.target.value, content?.question_type)}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {content?.question_type === "checkbox" && (
                                      <div className="space-y-4">
                                        <label className="text-base font-semibold text-gray-700">
                                          {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                        </label>
                                        <div className="space-y-4">
                                          <div className="space-y-3">
                                            {content?.options?.map((option) => (
                                              <Checkbox
                                                isChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value?.includes(option?._id) ? true : false}
                                                key={option?._id}
                                                checkboxBoxClass={"h-5 w-5 flex-shrink-0"}
                                                checkboxLabel={!option?.other ? option?.name : "Other"}
                                                onChange={(e) => onHandleChange(content?._id, "value", e.target.checked, content?.question_type, option?._id)}
                                              />
                                            ))}
                                          </div>
                                          {answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value?.includes(content?.options?.find((option) => option?.other)?._id) && (
                                            <Input
                                              inline={true}
                                              error={content?.validation?.required}
                                              label={"Specify other"}
                                              inputValue={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.other_value}
                                              onChange={(e) => onHandleChange(content?._id, "other_value", e.target.value, content?.question_type)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {content?.question_type === "select" && (
                                      <SelectMenu
                                        inline
                                        label={content?.name}
                                        required={content?.validation?.required}
                                        labelClass="mb-3 !font-semibold !text-base"
                                        startIndex={content?.options?.findIndex((option) => option?._id === answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value)}
                                        options={content?.options?.map((option) => ({ key: option?.name, value: option?._id }))}
                                        setOption={(option) => onHandleChange(content?._id, "value", option.value, content?.question_type)}
                                      />
                                    )}
                                    {content?.question_type === "file" && (
                                      <FileUpload
                                        content={content}
                                        selectedFiles={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value || []}
                                        onHandleChange={onHandleChange}
                                      />
                                    )}
                                    {["radio_grid", "checkbox_grid"]?.includes(content?.question_type) && (
                                      <FormChoiceGrid
                                        content={content}
                                        answer={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value || []}
                                        onHandleChange={onHandleChange}
                                      />
                                    )}
                                    {content?.question_type === "linear_scale" && (
                                      <div className="space-y-4">
                                        <RadioGroup
                                          required={content?.validation?.required}
                                          name={`option-${content?._id}`}
                                          labelClass={"!font-semibold"}
                                          subtitle={""}
                                          title={content?.name}
                                          layout={"scale"}
                                          scaleTitle={{ from: content?.linear_scale_from_label, to: content?.linear_scale_to_label }}
                                          defaultChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                          options={Array.from({ length: content?.linear_scale_to - content?.linear_scale_from + 1 }, (_, index) => content?.linear_scale_from + index)?.map((value) => ({ id: value, title: value }))}
                                          onChange={(id) => onHandleChange(content?._id, "value", parseInt(id), content?.question_type)}
                                        />
                                      </div>
                                    )}
                                    {content?.question_type === "date" && (
                                      <div className="h-[47px] w-full rounded-md border border-gray-300 !ring-0 focus-within:border-highlightColor md:h-[40px] 2xl:h-[47px]">
                                        <label className="m-0 flex  pl-3 pt-1.5 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3">
                                          {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                        </label>
                                        <div className="relative">
                                          <Flatpickr
                                            className="h-8 w-full rounded-md !border-none !ring-0"
                                            name="time"
                                            value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                            onChange={([selectedTime]) => onHandleChange(content?._id, "value", selectedTime ? new Date(selectedTime).toISOString() : "", content?.question_type)}
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "d.m.y",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {content?.question_type === "time" && (
                                      <div className="h-[47px] w-full rounded-md border border-gray-300 !ring-0 focus-within:border-highlightColor md:h-[40px] 2xl:h-[47px]">
                                        <label className="m-0 flex  pl-3 pt-1.5 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3">
                                          {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                        </label>
                                        <div className="relative">
                                          <Flatpickr
                                            className="h-8 w-full rounded-md !border-none !ring-0"
                                            name="time"
                                            value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                            onChange={([selectedTime]) => onHandleChange(content?._id, "value", selectedTime ? new Date(selectedTime).toISOString() : "", content?.question_type)}
                                            options={{
                                              enableTime: true,
                                              noCalendar: true,
                                              dateFormat: "h:i K",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    {content?.form?.sections && content?.form?.sections?.length > 0 && (
                      <div className="mx-auto mt-5 flex w-full flex-shrink flex-grow justify-end gap-x-3  pt-3">
                        {sectionIndex > 0 && (
                          <Button
                            isDisable={disabled}
                            buttonFunction={() => setSectionIndex((prevData) => prevData - 1)}
                            buttonClasses={"!bg-gray-400"}
                            buttonLabel={"Back"}
                          />
                        )}
                        <Button
                          isDisable={disabled}
                          buttonClasses={"!bg-highlightColor !px-6"}
                          buttonFunction={onHandleSubmit}
                          buttonLabel={content?.form?.sections?.length === sectionIndex + 1 ? "Submit" : "Next"}
                        />
                      </div>
                    )}
                  </div>
                ),
            )}
        </div>
      </div>
    </div>
  );
}
